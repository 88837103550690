import { lighten } from "@wessberg/color";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Link from "~/components/Link";
import MailchimpSignup from "~/components/MailchimpSignup";
import useNavigation from "~/hooks/useNavigation";
import { hSpacing, paragraph } from "~/styles/mixins";
import theme, { rem, smallerThan } from "~/styles/theme";

const FooterWrapper = styled.div`
  border-top: 1px solid ${(p) => p.theme.colors.dark};
  background-color: ${(p) => p.theme.colors.dark};
  color: ${(p) => p.theme.colors.light};
  a {
    color: ${(p) => p.theme.colors.light};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  position: relative;
  z-index: ${(p) => p.theme.z.footer};

  ${(p) => smallerThan(p.theme.breakpoints.ipadPortrait)} {
    padding-bottom: ${(p) => p.theme.sizes.mobileBottomBarHeight * 2}rem;
  }

  /*
  IDEA: use font size + em to animate??????
  */
  /*
  > div {
    &:nth-child(1) {
      height: 7px;
    }

    &:nth-child(2) {
      height: 9px;
    }

    &:nth-child(3) {
      height: 13px;
    }

    &:nth-child(4) {
      height: 21px;

    }

    &:nth-child(5) {
      height: 37px;
    }

    &:nth-child(6) {
      height: 69px;
    }

    &:nth-child(7) {

    }

    &:nth-child(8) {
    }
  }
  */
`;

const FooterLayerEmpty = styled.div`
  border-top: 5px solid ${(p) => p.theme.colors.light};
  position: relative;
`;

const FooterLayer = styled.div`
  /* ${hSpacing}; */
  position: relative;
  /* border-top: 5px solid ${(p) => p.theme.colors.light}; */
  padding: ${(p) => p.theme.sizes.vBlockSpacing}rem
    ${(p) => p.theme.sizes.hSpacing * 2}rem;
  ${(p) => smallerThan(p.theme.breakpoints.small)} {
    padding: ${(p) => p.theme.sizes.vBlockSpacingSmall}rem
      ${(p) => p.theme.sizes.hSpacingSmall * 2}rem;
  }
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 2fr;
  grid-gap: ${(p) => p.theme.sizes.hSpacing * 2}rem;
  ${(p) => smallerThan(p.theme.breakpoints.ipadPortrait)} {
    /* grid-auto-flow: row; */
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-auto-flow: row dense;
  }
`;

const FooterFooter = styled.div`
  height: ${(p) => p.theme.sizes.topBarHeight}rem;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: ${(p) => p.theme.sizes.hSpacing * 2}rem;
    right: ${(p) => p.theme.sizes.hSpacing * 2}rem;
    ${(p) => smallerThan(p.theme.breakpoints.small)} {
      left: ${(p) => p.theme.sizes.hSpacingSmall * 2}rem;
      right: ${(p) => p.theme.sizes.hSpacingSmall * 2}rem;
    }
    height: 1px;
    background-color: ${(p) => p.theme.colors.light};
    opacity: 0.2;
  }
  ${hSpacing};
  display: flex;
  align-items: center;

  > :last-child {
    margin-left: auto;
  }

  ${(p) => smallerThan(p.theme.breakpoints.ipadPortrait)} {
    align-items: flex-start;
    flex-direction: column;
    height: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    > :last-child {
      margin: 0 auto;
      margin-top: 4em;
    }
  }
`;

const FooterFooterTitle = styled.div`
  ${hSpacing};
  opacity: 1;
  font-weight: bold;
  font-size: ${rem("14px")};
`;

const FooterFooterItem = styled.div`
  ${hSpacing};
  /* opacity: 0.6; */
  color: ${(p) => lighten(p.theme.colors.light, -40)};
  > a {
    color: inherit;
    &:hover {
    }
  }
  font-size: ${rem("14px")};
  ${(p) => smallerThan(p.theme.breakpoints.ipadPortrait)} {
    height: 28px;
    line-height: 28px;
  }
`;

const FooterCol = styled.div``;
const FooterTitle = styled.h3`
  margin: 0;
  padding: 0;
  line-height: 2.2;
  font-weight: bold;
  font-size: ${rem("18px")};
`;

const FooterMenu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;
const FooterMenuItem = styled.li`
  line-height: 2.2;
  font-size: ${rem("14px")};
  /* opacity: 0.6; */
  color: ${(p) => lighten(p.theme.colors.light, -40)};
  a {
    color: ${(p) => lighten(p.theme.colors.light, -40)};
  }
`;

const SocialLinks = styled.ul`
  list-style: none;
  margin: 5px 0 0 0;
  padding: 0;
  > a {
    margin-right: 12px;

    > img {
      filter: brightness(0) invert(1);
      opacity: 0.6;
    }
  }
`;

const NewsletterLegal = styled.p`
  ${paragraph};
  color: ${(p) => lighten(p.theme.colors.light, -40)};
  margin-top: 1.2rem;
  font-size: 0.6rem !important;
  padding-right: 2rem;
`;

function Footer(props) {
  const countryData = useSelector((s) => s.countryData);

  const nav = useNavigation();

  const { asPath } = useRouter();
  const checkoutRoute = asPath.includes("/checkout");

  // return null;
  if (!nav) {
    return null;
  }

  return (
    <FooterWrapper id="footer">
      {!checkoutRoute && (
        <FooterLayer>
          {[0, 1, 2, 3].map((menuIndex) =>
            nav.footer.items[0].subNavs[menuIndex] ? (
              <FooterCol key={menuIndex}>
                <FooterTitle>
                  {nav.footer.items[0].subNavs[menuIndex].label}
                </FooterTitle>
                <FooterMenu>
                  {nav.footer.items[0].subNavs[menuIndex].items.map((menu) => (
                    <FooterMenuItem key={menu.label}>
                      <Link href={menu.href} as={menu.href_as || menu.href}>
                        <a
                          target={menu._blank ? "_blank" : "_self"}
                          rel={menu._blank ? "noopener noreferrer" : null}
                        >
                          {menu.label}
                        </a>
                      </Link>
                    </FooterMenuItem>
                  ))}
                </FooterMenu>
              </FooterCol>
            ) : (
              <FooterCol key={menuIndex} />
            )
          )}

          <FooterCol>
            <FooterTitle>Stay up to date</FooterTitle>
            <MailchimpSignup
              listIdentifier="d0c8cff255"
              color={lighten(theme.colors.light, -40)}
              backgroundColor={theme.colors.dark}
            />
            {/* <NewsletterLegal>
              By signing up for the newsletter i accept that AIAIAI can contact
              me by email with news, offers, event and competitions relating to
              our products and projects. We only collect your email address, and
              you are welcome to unsubscribe at any time.
            </NewsletterLegal> */}

            <FooterTitle style={{ marginTop: "50px" }}>Join us online</FooterTitle>
            <SocialLinks>
              <Link>
                <a
                  href="https://www.instagram.com/aiaiai.audio"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://images.prismic.io/aiaiai/4f4eec9f-596c-4da1-8e96-cc031ae8e52c_icon_instagram.png?auto=compress,format"
                    width="30"
                    height="30"
                  />
                </a>
              </Link>
              <Link>
                <a
                  href="https://www.facebook.com/aiaiaiaudio"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://images.prismic.io/aiaiai/42a70cd8-c41d-429f-9914-650b5d585b32_icon_facebook.png?auto=compress,format"
                    width="30"
                    height="30"
                  />
                </a>
              </Link>
              <Link>
                <a
                  href="https://twitter.com/aiaiaitweet"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://images.prismic.io/aiaiai/a3e7c676-0815-4d00-a09c-8a2025111079_icon_twitter.png?auto=compress,format"
                    width="30"
                    height="30"
                  />
                </a>
              </Link>
              <Link>
                <a
                  href="https://soundcloud.com/aiaiaiaudio"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://images.prismic.io/aiaiai/4fcaf25e-36d6-4df4-8e9b-de9e61b0d166_icon_soundcloud.png?auto=compress,format"
                    width="30"
                    height="30"
                  />
                </a>
              </Link>
              <Link>
                <a
                  href="https://www.youtube.com/channel/UCCH-YfPs69QdK2ETEAwrtzg"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://images.prismic.io/aiaiai/892a799b-a749-45a0-9899-178bedee4dbf_icon_youtube.png?auto=compress,format"
                    width="30"
                    height="30"
                  />
                </a>
              </Link>
            </SocialLinks>
          </FooterCol>
        </FooterLayer>
      )}
      <FooterFooter>
        {nav.legal.items.map((menu) => {
          const isChangeCountryMenu = menu.label === "Change country ${countryCode}";
          return (
            <FooterFooterItem key={menu.label}>
              <Link href={menu.href} as={menu.href_as || menu.href}>
                <a
                  target={menu._blank ? "_blank" : "_self"}
                  rel={menu._blank ? "noopener noreferrer" : null}
                >
                  {isChangeCountryMenu ? countryData.name : menu.label}
                </a>
              </Link>{" "}
              {isChangeCountryMenu && (
                <span
                  className={`flag-icons ${countryData.code}`}
                  style={{ transform: "translateY(-1px)", marginLeft: "2px" }}
                  title={`${countryData.name} (${countryData.code})`}
                ></span>
              )}
            </FooterFooterItem>
          );
        })}
        <FooterFooterItem key="copyright">
          &copy; 2025 AIAIAI. All Rights Reserved.
        </FooterFooterItem>
      </FooterFooter>
    </FooterWrapper>
  );
}

export default Footer;
